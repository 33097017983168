import React, { useEffect, useState } from "react";
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, TextField, useMediaQuery } from "@mui/material";
import ApiService from "../../services/api.service";
import SwitchBase from "@mui/material/internal/SwitchBase";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export interface SimpleDialogProps {
    open: boolean;
    setDialogOpen: (value: boolean) => void;
    data?: any;
}

const UserDialog = ({ setDialogOpen, open, data }: SimpleDialogProps) => {
    const [usersArray, setUsersArray] = useState<any[]>([]);
    const [formValues, setFormValues] = useState<{ [index: string]: any }>({});
    const isMobile = useMediaQuery('(max-width: 767px)')

    useEffect(() => {
        if(data) {
            //Combine both lists of users
            var allUsers: any = {}
            
            for(let user of data.admin_access) {
                allUsers[user.id] = user
                allUsers[user.id]['adminAccess'] = true
                allUsers[user.id]['adminNotification'] = false
            }

            for(let user of data.admin_notifications) {
                if(user.id in allUsers) {
                    allUsers[user.id]['adminNotification'] = true
                } else {
                    allUsers[user.id] = user
                    allUsers[user.id]['adminNotification'] = true
                    allUsers[user.id]['adminAccess'] = false
                }
            }

            setUsersArray(Object.values(allUsers));
        }
    }, [data]);

    const handleFormChange = (index: number, name: string, value: any) => {

        setUsersArray(usersArray.map((user, i) => index == i ? {...user, [name]: value} : user))
    }

    const handleCreateUser = () => {
        setUsersArray([...usersArray, { id: "", email: "", name: "", adminNotification: false, adminAccess: false }]);
    };

    const handleDeleteUser = (index: number) => {
        setUsersArray(usersArray.filter((user, i) => i != index))
    };

    const handleSubmit = () => {
        const notificationTransmissionData = new URLSearchParams();
        const accessTransmissionData = new URLSearchParams();

        notificationTransmissionData.append("id", data.id);
        accessTransmissionData.append("id", data.id);

        for (const user of usersArray) {
            if (user.adminNotification) {
                notificationTransmissionData.append("email[]", user.email);
            }
            if (user.adminAccess) {
                accessTransmissionData.append("email[]", user.email);
            }
        }

        ApiService.updateFormNotificationUser(notificationTransmissionData).then(
            (res) => {
                setDialogOpen(false);
            },
            (error) => {
                console.error(error);
            }
        );

        ApiService.updateFormAdminUser(accessTransmissionData).then(
            (res) => {
                setDialogOpen(false);
            },
            (error) => {
                console.error(error);
            }
        );
    };

    return (
        <Dialog onClose={() => setDialogOpen(false)} open={open} fullWidth maxWidth="sm">
            <DialogTitle>Manage Users</DialogTitle>
            <DialogContent>
                {/* {!isMobile && 
                    <>
                        <div className="row text-center">
                            <div className="col-6"></div>
                            <div className="col-4">Admin Level</div>
                            <div className="col-2"></div>
                        </div>
                        <div className="row mb-4 text-center">
                            <div className="col-6"></div>
                            <div className="col-2">Access</div>
                            <div className="col-2">Notification</div>
                            <div className="col-2"></div>
                        </div>
                    </>
                } */}
                {usersArray.map((item: any, index: number) => 
                    <div key={index}>
                        <div className="d-flex flex-row align-items-center flex-wrap my-4 text-center ">
                            <div className={isMobile ? "col-12" : "col-6"}>
                                <TextField fullWidth label="Email" name={`email`} variant="standard" defaultValue={item.email} onChange={(e: any) => handleFormChange(index, e.target.name, e.target.value)} />
                            </div>
                            <div className={isMobile ? "col-3" : "col-2"}>
                                <FormControlLabel 
                                    label="Admin Access"
                                    labelPlacement="top"
                                    control={<Checkbox name="adminAccess" checked={item.adminAccess} onChange={(e: any) => handleFormChange(index, e.target.name, e.target.checked)} />}
                                />
                            </div>
                            <div className={isMobile ? "col-3" : "col-2"}>
                                <FormControlLabel 
                                    label="Notification Access"
                                    labelPlacement="top"
                                    control={<Checkbox name="adminNotification" checked={item.adminNotification} onChange={(e: any) => handleFormChange(index, e.target.name, e.target.checked)} />}
                                />
                            </div>
                            <div className={isMobile ? "col-6" : "col-2"}>
                                <Button variant="outlined" color="error" onClick={() => handleDeleteUser(index)}>Remove</Button>
                            </div>
                        </div>
                        <Divider />
                    </div>
                )}
                <div className="mt-5 d-flex justify-content-between">
                    <Button variant="outlined" color="error" size="large" onClick={() => setDialogOpen(false)}>
                        Close
                    </Button>
                    <div>
                        <Button className="mx-2" variant="outlined" color="info" size="large" onClick={handleCreateUser}>
                            Add
                        </Button>
                        <Button variant="outlined" color="secondary" size="large" onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default UserDialog;
