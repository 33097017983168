import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import ApiService from "../../services/api.service";
import Papa from "papaparse";

interface Props {
    data: any;
    formName: string;
}

const ExportCsv = ({ data, formName }: Props) => {

    const { form_id, company_id } = useParams();

    const getFormData = async () => {
        ApiService.getDataById(form_id, 1, 999999999).then(
            (res) => {
                const result = res.data.result.data
                result.forEach((x: any) => {
                    delete x.ip_address
                    delete x.status_id
                    delete x.updated_at
                    delete x.deleted_at
                })

                const csvString = Papa.unparse(res.data.result.data)
                const url = window.URL.createObjectURL(new Blob([csvString]))
                const link = document.createElement('a')

                link.href = url
                link.setAttribute('download', `export_${formName}_${new Date().toISOString().replace('Z', '').replace('T', '_').replaceAll(':', '-')}.csv`)
                document.body.appendChild(link)
                
                link.click()
                link.remove()
            },
            (err) => {
                console.error(err);
            }
        )
    }

    return (
        <div className="mx-1 mb-2">
            <Button 
                variant="contained" 
                size="large" 
                onClick={getFormData}
            >
                Export to CSV
            </Button>
        </div>
    );
};

export default ExportCsv;
